dt {
  width: 80px;
  text-align: right;
  float: left;
  clear: left;
  color: #8795a1;
  font-weight: 500;
}

dd {
  width: 260px;
  float: left;
  margin-left: 10px;
  font-weight: 100;
  color: #b8c2cc;
}

.all-videos {
  width: 150px;
  height: 85px;
}
